import React from "react"
import Layout from "../components/layout"
import Seo from "../components/Seo"
import Head from "../components/Head"
import styled from "styled-components"
import breakpoints from "../components/GlobalStyle/globals/breakpoints"

const E = {
  Content: styled.div`
    overflow: hidden;
    z-index: 2;
    background: #fff;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
    border-radius: 40px 5px 40px 40px;
    padding: 30px;
    margin: 100px 0;

    h3 {
      padding: 10px 0;
      @media (max-width: ${breakpoints.large}px) {
        text-align: center;
      }
    }
    p {
      padding: 10px 0;
      line-height: 1.5rem;
      width: 100%;
    }

    h2 {
      font-size: 4rem;
      @media (max-width: ${breakpoints.medium}px) {
        font-size: 3rem;
      }
    }
  `,
}

const DiscPage = () => (
  <Layout>
    <Seo title="גילוי נאות" follow={false} index={false}/>
    <Head title="גילוי נאות" />

    <section>
      <div className="container">
        <E.Content>
          <h2>גילוי נאות לשיווק פינסיוני</h2>
          <p>
            פולאריס סוכנות לביטוח פנסיוני (2018) בע"מ הינה סוכנות ביטוח בבעלות
            פרטית, אשר עומדת בקשר עסקי עם הגופים המוסדיים המפורטים להלן. לצורך
            שיווק מוצרים פנסיוניים קיימת זיקה, כהגדרתה בחוק הפיקוח על שירותים
            פיננסים (עיסוק בייעוץ פנסיוני ושיווק פנסיוני) התשס"ה-2005, בין
            פולאריס סוכנות לביטוח פנסיוני (2018) בע"מ וגופים אלה לבין המוצרים
            הפנסיוניים המשווקים על ידי תמורה.
          </p>
          <h3>חברות ביטוח</h3>
          <p>
            כלל פנסיה וגמל בע"מ, מנורה מבטחים פנסיה בע"מ, הראל ניהול קרנות פנסיה
            בע"מ, מגדל מקפת קרנות פנסיה וקופות גמל בע"מ, הפניקס פנסיה וגמל בע"מ,
            מיטב דש גמל ופנסיה בע"מ*, אלטשולר שחם קופות גמל בע"מ*, פסגות פנסיה
            וגמל בע"מ*,
          </p>
          <h3>קרנות פנסיה</h3>
          <p>
            כלל פנסיה וגמל בע"מ, מנורה מבטחים פנסיה בע"מ, הראל ניהול קרנות פנסיה
            בע"מ, מגדל מקפת קרנות פנסיה וקופות גמל בע"מ, הפניקס פנסיה וגמל בע"מ,
            מיטב דש גמל ופנסיה בע"מ*, אלטשולר שחם קופות גמל בע"מ*, פסגות פנסיה
            וגמל בע"מ*,
          </p>
          <h3>קופות גמל</h3>
          <p>
            כלל פנסיה וגמל בע"מ, מיטב דש גמל ופנסיה בע"מ, פסגות פנסיה וגמל בע"מ,
            הפניקס -אקסלנס נשואה גמל ופנסיה בע"מ, ילין לפידות ניהול קופות גמל
            בע"מ, אלטשולר שחם קופות גמל בע"מ, מנורה מבטחים גמל בע"מ, הראל גמל
            והשתלמות בע"מ, מגדל מקפת קרנות פנסיה וקופות גמל בע"מ, מור קופות גמל
            בע"מ, .
          </p>
          <h3>קרנות השתלמות</h3>
          <p>
            כלל פנסיה וגמל בע"מ, מיטב דש גמל ופנסיה בע"מ, ילין לפידות ניהול
            קופות גמל בע"מ, אלטשולר שחם קופות גמל בע"מ, הפניקס -אקסלנס נשואה גמל
            ופנסיה בע"מ, מגדל מקפת קרנות פנסיה וקופות גמל בע"מ, פסגות פנסיה וגמל
            בע"מ, הראל גמל והשתלמות בע"מ, מור קופות גמל בע"מ, מנורה מבטחים גמל
            בע"מ, .
          </p>
        </E.Content>
      </div>
    </section>
  </Layout>
)

export default DiscPage
